import { FormControl } from '@angular/forms';

export const serviceProviderTypes = [
  { value: 'contractor', label: 'General Contractor' },
  { value: 'subcontractor', label: 'Subcontractor' },
  { value: 'architect', label: 'Architect' },
  { value: 'inspector', label: 'Inspector' },
  { value: 'engineer', label: 'Engineer' },
  { value: 'material_supplier', label: 'Material Supplier' },
  { value: 'consultant', label: 'Consultant' },
];

export type RegistrationForm = {
  company_name: FormControl;
  first_name: FormControl;
  last_name: FormControl;
  email: FormControl;
  phone: FormControl;
  password: FormControl;
  password_confirmation: FormControl;
  type: FormControl;
  license?: FormControl;
};

export type RegistrationData = {
  company_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  type: string;
  license?: string;
  invitation_token?: string;
  billing_plan_id?: number;
  subscription_recurring_interval?: 'month' | 'year';
};

<ng-scrollbar class="std-scrollbar">
  <div class="register-wrapper">
    <h1 class="title">Welcome To Skillhop!</h1>
    @if (selectedPlan()) {
      <div class="mt-2 mb-0 text-shade_2 text-size_3xl">
        Your selected plan is: <span class="font-semibold">{{ selectedPlan() | titlecase }}.</span>
      </div>
    }
    <div class="mt-2 mb-0 text-shade_2 text-size_3xl">
      @if (isOnlyManager()) {
        You can register only as manager.
      } @else {
        Select your account category
      }
    </div>
    <div class="btn-container">
      <button
        (click)="toggleAccountType(MAIN_USER_TYPES.MANGER)"
        class="landing-btn cubic btn-height-big w-100"
        [ngClass]="{
          'outline-btn': accountType() === MAIN_USER_TYPES.MANGER,
          'btn-disabled': accountType() !== MAIN_USER_TYPES.MANGER
        }"
      >
        Managers
      </button>
      <button
        (click)="toggleAccountType(MAIN_USER_TYPES.GENERAL_CONTRACTOR)"
        class="landing-btn cubic btn-height-big w-100 ml-4"
        [ngClass]="{
          'outline-btn': accountType() === MAIN_USER_TYPES.GENERAL_CONTRACTOR,
          'btn-disabled': accountType() !== MAIN_USER_TYPES.GENERAL_CONTRACTOR,
          disabled: isOnlyManager()
        }"
      >
        General Contractor
      </button>
      <button
        (click)="toggleAccountType(MAIN_USER_TYPES.SERVICE_PROVIDER)"
        class="landing-btn cubic btn-height-big w-100 ml-4"
        [ngClass]="{
          'outline-btn': accountType() === MAIN_USER_TYPES.SERVICE_PROVIDER,
          'btn-disabled': accountType() !== MAIN_USER_TYPES.SERVICE_PROVIDER,
          disabled: isOnlyManager()
        }"
      >
        Service Providers
      </button>
    </div>
    <div class="form-wrapper" (keyup.enter)="register()">
      @if (accountType() === MAIN_USER_TYPES.MANGER) {
        <app-register-manager-form
          @fadeInCustom
          [invitationToken]="invitationToken()"
          [registrationForm]="registrationForm"
        >
        </app-register-manager-form>
      } @else if (accountType() === MAIN_USER_TYPES.SERVICE_PROVIDER) {
        <app-register-service-provider-form
          @fadeInCustom
          [registrationForm]="registrationForm"
        ></app-register-service-provider-form>
      } @else if (accountType() === MAIN_USER_TYPES.GENERAL_CONTRACTOR) {
        <app-register-general-contractor-form
          @fadeInCustom
          [registrationForm]="registrationForm"
        ></app-register-general-contractor-form>
      }

      <div class="form-container gap-y-8 grid-">
        <div class="login-instead order-3 sm:order-1">
          <button class="landing-btn outline-btn btn-big w-100 cubic" mat-button routerLink="/auth/login">
            LOGIN INSTEAD
          </button>
        </div>
        <div class="order-2">
          <button
            class="landing-btn yellow-btn btn-big w-100 cubic"
            [ngClass]="{ 'btn-disabled': registrationForm.invalid || loading() }"
            mat-button
            [matTooltip]="registrationForm.invalid ? 'Please enter valid data' : ''"
            (click)="registrationForm.valid && register()"
          >
            REGISTER
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-scrollbar>

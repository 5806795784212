import { Component, Input } from '@angular/core';
import { FloatingInputComponent } from '../../../../framework/inputs/floating-input/floating-input.component';
import { PaginatorModule } from 'primeng/paginator';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RegistrationForm } from '../../auth.constants';

@Component({
  selector: 'app-register-manager-form',
  standalone: true,
  imports: [FloatingInputComponent, PaginatorModule, ReactiveFormsModule],
  templateUrl: './register-manager-form.component.html',
  styleUrls: ['./register-manager-form.component.scss'],
})
export class RegisterManagerFormComponent {
  @Input({ required: true }) registrationForm: FormGroup<RegistrationForm>;
  @Input({ required: true }) invitationToken: string;
}

import { Component, Input } from '@angular/core';
import { DropdownComponent } from '../../../../framework/inputs/dropdown/dropdown.component';
import { RegistrationForm, serviceProviderTypes } from '../../auth.constants';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FloatingInputComponent } from '../../../../framework/inputs/floating-input/floating-input.component';

@Component({
  selector: 'app-register-service-provider-form',
  standalone: true,
  imports: [DropdownComponent, ReactiveFormsModule, FloatingInputComponent],
  templateUrl: './register-service-provider-form.component.html',
  styleUrl: './register-service-provider-form.component.scss',
})
export class RegisterServiceProviderFormComponent {
  @Input({ required: true }) registrationForm: FormGroup<RegistrationForm>;

  protected readonly serviceProviderTypes = serviceProviderTypes;
}

import { Component, Input } from '@angular/core';
import { FloatingInputComponent } from '../../../../framework/inputs/floating-input/floating-input.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RegistrationForm } from '../../auth.constants';

@Component({
  selector: 'app-register-general-contractor-form',
  standalone: true,
  imports: [FloatingInputComponent, ReactiveFormsModule],
  templateUrl: './register-general-contractor-form.component.html',
  styleUrl: './register-general-contractor-form.component.scss',
})
export class RegisterGeneralContractorFormComponent {
  @Input({ required: true }) registrationForm: FormGroup<RegistrationForm>;
}

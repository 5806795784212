<form class="form-container" [formGroup]="registrationForm">
  <app-floating-input
    [autoComplete]="false"
    label="Company"
    placeholder="Company"
    formControlName="company_name"
    ngDefaultControl
  >
  </app-floating-input>
  <app-floating-input
    [disabled]="!!invitationToken"
    [autoComplete]="false"
    label="Email"
    placeholder="Email"
    formControlName="email"
    ngDefaultControl
  >
  </app-floating-input>

  <app-floating-input
    [autoComplete]="false"
    label="First Name"
    placeholder="First Name"
    formControlName="first_name"
    ngDefaultControl
  >
  </app-floating-input>
  <app-floating-input
    [autoComplete]="false"
    label="Phone"
    placeholder="Phone"
    formControlName="phone"
    type="tel"
    ngDefaultControl
  >
  </app-floating-input>

  <app-floating-input
    name="wasd"
    [autoComplete]="false"
    label="Last Name"
    placeholder="Last Name"
    formControlName="last_name"
    ngDefaultControl
  >
  </app-floating-input>
  <div class="flex gap-4">
    <app-floating-input
      [autoComplete]="false"
      class="min-w-0 flex-1"
      type="password"
      label="Password"
      placeholder="Password"
      formControlName="password"
      ngDefaultControl
    >
    </app-floating-input>
    <app-floating-input
      [autoComplete]="false"
      class="min-w-0 flex-1"
      type="password"
      label="Re-Enter"
      placeholder="Re-Enter"
      formControlName="password_confirmation"
      ngDefaultControl
    >
    </app-floating-input>
  </div>
</form>
